<template>
  <div id="app">
    <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/gallery">Gallery</router-link>
      <div class="language-switcher">
        <span @click="changeLanguage('en')">🇬🇧</span>
        <span @click="changeLanguage('fr')">🇫🇷</span>
        <span @click="changeLanguage('de')">🇩🇪</span>
        <span @click="changeLanguage('it')">🇮🇹</span>
      </div>
    </nav>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    changeLanguage(locale) {
      this.$i18n.locale = locale;
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
nav {
  background-color: #0c1606;
    padding: 15px;
    border-bottom: solid 1px white;
    /* border-radius: 8px; */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
nav a {
  margin: 0 20px;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 1.2em;
}
nav a.router-link-exact-active {
  text-decoration: underline;
}

.language-switcher {
  display: inline-block;
  margin-left: 20px;
}
.language-switcher span {
  cursor: pointer;
  margin: 0 5px;
  font-size: 1.5em;
}
.language-switcher span:hover {
  text-decoration: underline;
}

body {
  margin: 0;
  padding: 0;
}

</style>