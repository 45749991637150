<template>
    <div ref="lottieContainer" class="lottie-container"></div>
</template>

<script>
import lottie from 'lottie-web';

export default {
    name: 'LottieAnimation',
    props: {
        animationData: {
            type: Object,
            required: true
        }
    },
    mounted() {
        lottie.loadAnimation({
            container: this.$refs.lottieContainer,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: this.animationData
        });
    }
};
</script>

<style scoped>
.lottie-container {
    width: 100px;
    left: 65px;
    height: fit-content;
    position: relative;
}
</style>