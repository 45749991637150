<template>
    <div class="home">
        <div class="overlay"></div>

        <div class="about">
            <img src="@/assets/gallery/1.jpg" alt="Company Image" class="about-image">
            <div class="about-text">
                <h1>{{ $t('welcome') }}</h1>
                <p>{{ $t('description') }}</p>
            </div>
        </div>

        <SlideshowComponent />

        <!-- Contact Section -->
        <div class="contact">
            <h2>{{ $t('contact_us') }}</h2>
            <p>{{ $t('email') }}: <a href="mailto:swissgreenmedic@gmail.com">swissgreenmedic@gmail.com</a></p>
            <p>{{ $t('address') }}: Feldstrasse 16, 4663 Aarburg</p>
            <p>{{ $t('website') }}: <a href="https://Swissgreenmedic.ch" target="_blank">Swissgreenmedic.ch</a></p>
            <p>{{ $t('phone') }}: <a href="tel:+41766961084">+41766961084</a></p>
        </div>
    </div>
</template>

<script>
import SlideshowComponent from '@/components/SlideshowComponent.vue';

export default {
    name: 'HomePage',
    components: {
        SlideshowComponent
    }
};
</script>

<style scoped>
.home {
    position: relative;
    text-align: center;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background: url('@/assets/background.jpg') no-repeat center center fixed;
    background-size: cover;
    color: white;
}

.overlay {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    z-index: 1;
}

.home > * {
    position: relative;
    z-index: 2;
}

.about {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255 255 255 / 39%);
    color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    margin: 50px auto;
    padding: 20px;
    max-width: 800px;
    border: solid 2px #ffffff91;
}

.about:hover {
    transform: translateY(-10px);
}

.about-image {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-right: 20px;
    border: solid 1px black;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text {
    flex: 1;
    text-align: left; /* Ensure text is left-aligned */
}

.about h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: #022405;
}

.about p {
    font-size: 1.2em;
    color: #042a07;
    font-weight: bold;
    margin-bottom: 20px;
}

.contact {
    background-color: rgb(0 0 0 / 51%);
    color: #333;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    margin-top: 50px;
    border: solid 2px white;
    padding: 20px;
}

.contact:hover {
    transform: translateY(-10px);
}

.contact h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    color: white;;
}

.contact p {
    font-size: 1.2em;
    color: white;;
    margin-bottom: 20px;
}

.contact a {
    color: #42b983;
    text-decoration: none;
}

.contact a:hover {
    text-decoration: underline;
}
</style>