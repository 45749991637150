import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';
import en from './locale/en.json';
import fr from './locale/fra.json';
import de from './locale/ger.json';
import it from './locale/ita.json';

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages: {
    en,
    fr,
    de,
    it
  }
});

createApp(App).use(router).use(i18n).mount('#app');