import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './views/HomePage.vue';
import GalleryPage from './views/GalleryPage.vue';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: GalleryPage
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;