<template>
    <div class="gallery">
        <div class="overlay"></div>
        <h1>Gallery</h1>
        <div class="images">
            <img src="@/assets/gallery/23.jpg" alt="Image 23">
            <img src="@/assets/gallery/25.jpg" alt="Image 25">
            <img src="@/assets/gallery/26.jpg" alt="Image 26">
            <img src="@/assets/gallery/27.jpg" alt="Image 27">
            <img src="@/assets/gallery/28.jpg" alt="Image 28">
            <img src="@/assets/gallery/1.jpg" alt="Image 2">
            <img src="@/assets/gallery/3.jpg" alt="Image 3">
            <img src="@/assets/gallery/4.jpg" alt="Image 4">
            <img src="@/assets/gallery/5.jpg" alt="Image 5">
            <img src="@/assets/gallery/6.jpg" alt="Image 6">
            <img src="@/assets/gallery/7.jpg" alt="Image 7">
            <img src="@/assets/gallery/8.jpg" alt="Image 8">
            <img src="@/assets/gallery/9.jpg" alt="Image 9">
            <img src="@/assets/gallery/10.jpg" alt="Image 10">
            <img src="@/assets/gallery/11.jpg" alt="Image 11">
            <img src="@/assets/gallery/12.jpg" alt="Image 12">
            <img src="@/assets/gallery/13.jpg" alt="Image 13">
            <img src="@/assets/gallery/14.jpg" alt="Image 14">
            <img src="@/assets/gallery/15.jpg" alt="Image 15">
            <img src="@/assets/gallery/16.jpg" alt="Image 16">
            <img src="@/assets/gallery/17.jpg" alt="Image 17">
            <img src="@/assets/gallery/19.jpg" alt="Image 19">
            <img src="@/assets/gallery/20.jpg" alt="Image 20">
            <img src="@/assets/gallery/21.jpg" alt="Image 21">
            <img src="@/assets/gallery/22.jpg" alt="Image 22">
        </div>
    </div>
</template>

<script>
export default {
    name: 'GalleryPage'
};
</script>

<style scoped>
.gallery {
    position: relative;
    text-align: center;
    padding: 20px;
    font-family: 'Arial', sans-serif;
    background: url('@/assets/background.jpg') no-repeat center center fixed;
    background-size: cover;
    color: white;
}

.overlay {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); /* Dark overlay */
    z-index: 1;
}

.gallery > * {
    position: relative;
    z-index: 2;
}

.images {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.images img {
    margin: 10px;
    max-width: calc(31.333% - 20px);
    height: auto;
    flex: 1 1 calc(34.333% - 20px);
    border-radius: 10px;
    box-shadow: rgb(0 0 0) 0px 0px 22px;
    border: solid 2px #ffffff6e;
}
</style>