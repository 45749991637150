<template>
    <div class="slideshow-container">
        <div class="slideshow">
            <div v-for="(slide, index) in slides" :key="index" class="slide" v-show="currentSlide === index">
                <img :src="slide.image" :alt="$t(slide.brand)">
                <div class="slide-content">
                    <h2>{{ $t(slide.brand) }}</h2>
                    <p>{{ $t(slide.description) }}</p>
                </div>
            </div>
            <button @click="prevSlide" class="prev">‹</button>
            <button @click="nextSlide" class="next">›</button>
        </div>
        <div class="company-info">
            <h2>{{ $t('about_our_company') }}</h2>
            <p>{{ $t('company_description') }}</p>
            <div class="social-media">
                <a href="https://www.instagram.com/swissgreenmedic/" target="_blank">
                    <img src="@/assets/instagram-icon.png" alt="Instagram" class="instagram-icon">
                </a>
            </div>
            <LottieAnimation :animationData="lottieAnimationData" />
        </div>
    </div>
</template>

<script>
import LottieAnimation from './LottieAnimation.vue';
import lottieAnimationData from '@/assets/weed.json';

export default {
    name: 'SlideshowComponent',
    components: {
        LottieAnimation
    },
    data() {
        return {
            currentSlide: 0,
            slides: [
                { image: require('@/assets/gallery/14.jpg'), brand: 'hq_cbd', description: 'hq_cbd_description' },
                { image: require('@/assets/gallery/13.jpg'), brand: 'amnesia_cbd', description: 'amnesia_cbd_description' },
                { image: require('@/assets/gallery/3.jpg'), brand: 'limoncello_cbd', description: 'limoncello_cbd_description' },
                { image: require('@/assets/gallery/4.jpg'), brand: 'orangelo_cbd', description: 'orangelo_cbd_description' }
            ],
            lottieAnimationData
        };
    },
    mounted() {
        this.startSlideshow();
    },
    methods: {
        nextSlide() {
            this.currentSlide = (this.currentSlide + 1) % this.slides.length;
        },
        prevSlide() {
            this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
        },
        startSlideshow() {
            setInterval(this.nextSlide, 6000);
        }
    }
};
</script>

<style scoped>
.slideshow-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 50px auto;
    width: 800px;
}
.slideshow {
    position: relative;
    width: 70%;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.slide {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 0.5s ease-in-out;
    border: solid 2px white;
    border-radius: 12px;
}
.slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}
.slide-content {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
}
.slide-content h2 {
    font-size: 1.5em;
    margin: 0;
}
.slide-content p {
    font-size: 1em;
    margin: 5px 0 0;
}
button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 2em;
    border-radius: 50%;
}
button.prev {
    left: 10px;
}
button.next {
    right: 10px;
}
button:hover {
    background-color: rgba(0, 0, 0, 0.7);
}
.company-info {
    width: 28%;
    padding: 20px;
    align-self: center;
    height: 436px;
    background-color: #f9f9f9;
    border-radius: 10px;
    border-top-left-radius: 0px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-bottom-left-radius: 0;
    background-color: #000000a1;
    border: solid 1px white;
    border-left: 0;
}
.social-media {
    margin-top: 20px;
    text-align: center;
}
.instagram-icon {
    width: 30px;
    height: 30px;
}

/* Add media query for smaller screens */
@media (max-width: 925px) {
    .slideshow-container {
        display: block;
        width: 100%;
    }
    .slideshow {
        width: 100%;
        margin-bottom: 20px;
    }
    .company-info {
        width: 88% !important;
        height: auto;
        border-radius: 10px ;
    background-color: #000000a1;
    border: solid 1px white;
    }

    .home {
        text-align: -webkit-center;
    }

    .lottie-container {
        display: none !important;
    }

}
</style>